.Consultancy {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 20px;
    margin-top: 100px;
}

.consultancy-container {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 800px;
    width: 100%;
    text-align: left;
}

.consultancy-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: black;
    font-weight: bold;
}

.consultancy-subtitle {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: black;
    font-weight: 600;
}

.consultancy-list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
}

.consultancy-list li {
    margin-bottom: 10px;
}

.consultancy-description {
    font-size: 1rem;
    line-height: 1.6;
    color: black;
    margin-bottom: 20px;
}

.consultancy a {
    color: #007bff;
    text-decoration: none;
}

.consultancy a:hover {
    text-decoration: underline;
}

.make-a-booking-button {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.make-a-booking-button:hover {
    background-color: #0056b3;
}

@media screen and (min-width: 769px) {
    .consultancy-container {
        width: 60%;
    }
}
