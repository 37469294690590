/* Algemeen voor de updates sectie */
.updates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #F9FAFB; /* Soft, neutral background for the section */
    border-radius: 12px; /* Slightly rounded corners */
    padding: .5rem;
    margin: 20px 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow for modern feel */
    font-family: 'Arial', sans-serif;
}

/* De titel van de laatste ontwikkelingen */
.updates h3 {
    font-size: 1.7rem;
    color: #2D3748; /* Darker shade for better contrast */
    /*margin-bottom: .5rem;*/
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
}

/* Stijl voor de update items */
.update-item {
    font-size: 1.2rem;
    color: #4A5568; /* Lighter text color for readability */
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    position: relative;
    padding: 20px;
    background-color: #ffffff; /* White background for individual items */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for subtle depth */
    transition: all 0.3s ease;
}

/* Hover effect to make update items stand out */
.update-item:hover {
    background-color: #F7FAFC; /* Lighter background on hover */
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow */
}

/* Align the row with icon and type in a horizontal layout */
.align-update-item-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

/* Stijl voor de update icon */
.update-icon {
    font-size: 2rem;
    color: #3182CE; /* Soft blue for icons */
    transition: color 0.3s ease;
}

/* Hover effect for icon */
.update-icon:hover {
    color: #48BB78; /* Green on hover for icons */
}

/* Stijl voor de update type tekst (bijvoorbeeld: Nieuw project) */
.update-type {
    font-weight: bold;
    font-size: 1.1rem;
    color: #2D3748; /* Dark text for type */
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* De update beschrijving (title) als link */
.update-description {
    color: #3182CE; /* Blue for title link */
    text-decoration: none; /* No underline by default */
    font-weight: bold;
    font-size: 1.2rem;
    transition: color 0.3s ease, background-color 0.3s ease, transform 0.2s ease; /* Added transition effects */
    padding: .5rem 1rem; /* More padding for better spacing */
    border: 2px solid #0056b3;
    border-radius: 5px; /* Rounded corners for a smoother look */
    background-color: #f0f8ff; /* Subtle light background */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

/* Hover effect for a more interactive feel */
.update-description:hover {
    color: #ffffff; /* White text on hover */
    background-color: #0056b3; /* Darker background on hover */
    border-color: #003366; /* Darker border on hover */
    transform: translateY(-2px); /* Slightly raise the element */
}

/* Focus style for accessibility */
.update-description:focus {
    outline: 3px solid #ffcc00; /* Bright yellow outline for better visibility */
    outline-offset: 2px;
}
/* Loading state stijl */
.updates p {
    font-size: 1.2rem;
    color: #A0AEC0; /* Lighter grey for loading text */
    text-align: center;
}

/* Toevoegen van een subtiele animatie voor de loading text */
.updates p.loading {
    animation: fadeIn 1.5s infinite;
}

/* Simple fadeIn animation for loading text */
@keyframes fadeIn {
    0% { opacity: 0.3; }
    50% { opacity: 1; }
    100% { opacity: 0.3; }
}
