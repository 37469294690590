/* HeroSection.css */

.hero-section {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;


    background: #004ecb; /* Set your preferred background color */
    color: #fff; /* Set your preferred text color */
    padding-top: 4rem;
    padding-bottom: 5rem;

}

.hero-section h1 {
    font-size: 3em; /* Adjust the font size as needed */
    margin-bottom: 0.3rem;
}

.hero-section p {
    font-size: 1.2em; /* Adjust the font size as needed */
    margin-bottom: 1.5rem;
}

.hero-section button {
    background: #fff; /* Set your preferred button background color */
    color: #000509; /* Set your preferred button text color */
    padding: 10px 20px;
    font-size: 1.2rem; /* Adjust the font size as needed */
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
}

.hero-section button:hover {
    background: #E0E0E0; /* Set your preferred button background color on hover */
    color: #05090e; /* Set your preferred button text color on hover */
}

.rondleiding-button {
    display: flex;
    align-items: center;
}
.rondleiding-icon {
    font-size: 1.5rem;
    margin-left: 0.5rem;
}

.hero-section-logo {
    width: 15rem;
    height: auto;
}