.ActionRouteToPricing {
    background: #4285f4; /* Google blue */
    display: flex;
    flex-direction: column; /* Align items horizontally */
    align-items: center;
    justify-content: start;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    color: white; /* Text color */
}

.ActionRouteToPricing .action-route-to-pricing-content {

    flex: 1; /* Take remaining space */
    margin: 0;
    padding: 5px;
}

.action-route-betaalplan {
    padding: 10px 20px; /* Adjusted padding */
    background-color: #ffffff; /* White background */
    border-radius: 5px;
    border: 2px solid #ffffff; /* White border */
    color: #4285f4; /* Google blue text color */
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-flex;
    gap: 5px;
    font-size: 1.2rem;
}

.action-route-betaalplan:hover {
    background-color: #f0f0f0; /* Light grey on hover */
}

/*.action-route-betaalplan-icon {*/
/*    font-size: 1.5rem;*/
/*}*/
