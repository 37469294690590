.m-header {
    background-color: #004ecb;
    color: #54B1FFFF;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100%;
    z-index: 1000;
    height: 4rem;
    transition: background-color 3s ease; /* Transition for background color */
}

.m-header.rainy-bg {
    background-color: #003A96; /* Transition to the new color when on the offerte route */
    animation: rainyEffect 1s ease-in-out infinite;
}

@keyframes rainyEffect {
    0% {
        background-image:
                linear-gradient(45deg, rgba(0, 58, 150, 0.4) 25%, transparent 25%),
                linear-gradient(135deg, rgba(0, 58, 150, 0.3) 25%, transparent 25%),
                linear-gradient(45deg, rgba(0, 58, 150, 0.5) 25%, transparent 25%);
        background-size: 50px 50px, 75px 75px, 100px 100px;
        background-position: 0px 0px, -50px -50px, 50px 50px;
    }
    25% {
        background-image:
                linear-gradient(45deg, rgba(0, 58, 150, 0.5) 25%, transparent 25%),
                linear-gradient(135deg, rgba(0, 58, 150, 0.35) 25%, transparent 25%),
                linear-gradient(45deg, rgba(0, 58, 150, 0.45) 25%, transparent 25%);
        background-size: 50px 50px, 80px 80px, 120px 120px;
        background-position: 0px 50px, -75px -75px, 25px 25px;
    }
    50% {
        background-image:
                linear-gradient(45deg, rgba(0, 58, 150, 0.6) 25%, transparent 25%),
                linear-gradient(135deg, rgba(0, 58, 150, 0.4) 25%, transparent 25%),
                linear-gradient(45deg, rgba(0, 58, 150, 0.5) 25%, transparent 25%);
        background-size: 60px 60px, 85px 85px, 140px 140px;
        background-position: 0px 100px, -90px -90px, 40px 40px;
    }
    75% {
        background-image:
                linear-gradient(45deg, rgba(0, 58, 150, 0.5) 25%, transparent 25%),
                linear-gradient(135deg, rgba(0, 58, 150, 0.35) 25%, transparent 25%),
                linear-gradient(45deg, rgba(0, 58, 150, 0.45) 25%, transparent 25%);
        background-size: 70px 70px, 90px 90px, 150px 150px;
        background-position: 0px 150px, -125px -125px, 75px 75px;
    }
    100% {
        background-image:
                linear-gradient(45deg, rgba(0, 58, 150, 0.4) 25%, transparent 25%),
                linear-gradient(135deg, rgba(0, 58, 150, 0.3) 25%, transparent 25%),
                linear-gradient(45deg, rgba(0, 58, 150, 0.5) 25%, transparent 25%);
        background-size: 50px 50px, 75px 75px, 100px 100px;
        background-position: 0px 200px, -150px -150px, 100px 100px;
    }
}
.header-logo {
    height: auto;
    width: 10rem;
    border-radius: 5px;
    margin-left: 0.5rem;
    z-index: 2;

}

.m-header h1 {
    font-size: 21px;
    margin: 0;
    color: #54b1ff;
}

.title-and-menu {
    display: flex;
    align-items: center;
}

.title-and-menu-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: inherit; /* Ensure it inherits the color */
}

.nav-links {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #656060;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    text-align: center;
    transform: translateX(-120%);
    transition: transform 0.3s ease-in-out, background-color 1s ease-in-out;
    overflow-y: auto;
    z-index: 1;
    gap: 3rem;
}

.nav-links.open {
    transform: translate(0, 0);
}

.nav-links.rainy-bg {
    background-color: #003A96;
    animation: rainyEffect 1s ease-in-out infinite;
}


.m-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 1.2rem;

}

.m-header li {
    margin: 10px 0;
}

.m-header a {
    position: relative; /* Make the link a positioned element */
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    transition: color 0.3s ease;
}

.m-header .nav-link:hover,
.m-header .nav-link.active {
    color: greenyellow;
}

.m-header .nav-link::after,
.m-header .nav-link.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; /* Adjust to ensure the underline appears just below the text */
    width: 0;
    height: 2px; /* Adjust the height of the underline */
    background-color: greenyellow; /* Color of the underline */
    transition: width 0.3s ease-in-out;
}

.m-header .nav-link:hover::after,
.m-header .nav-link.active::after {
    width: 100%; /* Full width on hover */
}

.action-button {
    background-color: #FFFFFF; /* White background to make text readable */
    color: #010710; /* Google's blue for text color */
    border: 2px solid #4285F4; /* Border around button */
    padding: 10px 20px;
    font-size: 1.2rem;
    margin-left: 2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
}

.action-button:hover {
    background-color: #E0E0E0; /* Light gray background on hover */
}

.action-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.5); /* Google's blue with opacity for focus */
}


.menu-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 2;
    margin-left: 50px;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: transform 0.3s ease, background-color 0.3s ease;


}

.menu-toggle.open .bar:first-child {
    transform: rotate(-45deg) translate(-11px, 13px);

}

.menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
}

.menu-toggle.open .bar:last-child {
    transform: rotate(45deg) translate(-5px, -6px);


}

@media screen and (max-width: 769px) {
    .action-button {
        margin-left: 0;
        margin-top: 2rem;
    }
}

@media screen and (min-width: 769px) {
    .m-header {
        flex-direction: row;
        align-items: center;
    }


    .nav-links {
        position: static;
        transform: none;
        flex-direction: row;
        background-color: transparent;
        width: auto;
        height: auto;
        overflow-y: visible;
    }

    .m-header ul {
        flex-direction: row;
    }

    .m-header li {
        margin: 0 15px;
    }

    .m-header a {
        font-size: 1.2rem;
    }
}

