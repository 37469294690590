.TechDictionary {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin: 100px auto;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}

.search-input {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
}

.search-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.search-button:hover {
    background-color: #0056b3;
}

.tech-dictionary-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: black;
}

.concept-articles {
    width: 100%;
}

.concept-article {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.concept-article:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.concept-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #007bff;
}

.concept-description {
    font-size: 16px;
    line-height: 1.6;
    color: black;
    margin-bottom: 10px;
}

.full-explanation-link {
    color: #1A73E8;
    text-decoration: underline;
    font-weight: bold;
    transition: color 0.3s ease;
}

.full-explanation-link:hover {
    color: #0056b3;
}
