.ActionAPI {
    padding: 20px;
    background-color: #f9f9f9;
    margin-top: 100px;
}

.api-container {
    max-width: 800px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.api-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.api-description {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.5;
}

.api-subtitle {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}

.api-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
}

.api-list li {
    margin-bottom: 10px;
}

.api-docs-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.api-docs-button:hover {
    background-color: #0056b3;
}
