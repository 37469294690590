.Pricing {
    text-align: center;
    padding: 2rem;
    background-color: #f9f9f9;
    max-width: 800px;
    margin: 60px auto 0;

}

.pricing-heading {
    font-size: 2.5rem;
    color: #202124; /* Google's primary text color */
    margin-bottom: 1rem;
}

.pricing-title {
    font-size: 1.75rem;
    color: #202124;
    margin-bottom: 0.5rem;
}

.pricing-subtitle {
    font-size: 1.3rem;
    color: #4285F4; /* Google's accent color */
    margin-bottom: 1rem;
}

.pricing-paragraph {
    margin-bottom: 1rem;
    color: #5f6368; /* Google's secondary text color */
}

.pricing-link {
    color: #1A73E8; /* Google's link color */
    text-decoration: underline;
}

.pricing-check-item-icon {
    color: #42cb0c; /* Google's success or checkmark color */
}

.pricing-row-one, .pricing-row-two {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.pricing-one, .pricing-two, .pricing-three, .pricing-custom {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 320px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.pricing-one:hover, .pricing-two:hover, .pricing-three:hover, .pricing-custom:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.li-pricing-features {
    margin-bottom: 1rem;
    list-style: none;
}

.ul-pricing-feature {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: 0.5rem;
    padding: 0.25rem 0;
}

.ul-pricing-feature .pricing-check-item-icon {
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .pricing-row-one, .pricing-row-two {
        flex-direction: column;
        align-items: center;
    }

    .pricing-one, .pricing-two, .pricing-three, .pricing-custom {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1.5rem;
    }
}
